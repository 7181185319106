import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  background: #fff;
  width: 500px;
  border: 0;
  border-radius: 4px;
  padding: 20px;

  button.compras {
    margin: 10px 0 0;
    height: 33px;
    background: #63cc2b;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    width: 100%;

    &:hover {
      background: ${darken(0.03, '#63cc2b')};
    }
  }
`;

export const Info = styled.div`
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;

  div.header {
    display: flex;
    justify-content: space-between;

    button {
      background: none;
      border: 0;
    }

    p {
      font-size: 16px;
      font-weight: bold;
      color: #000;
    }
  }

  div {
    margin-top: 5px;
    margin-bottom: 5px;

    font-weight: bold;
    color: #333;

    span {
      font-weight: normal;
      color: #666;
    }

    a {
      display: flex;
      align-items: center;

      span {
        color: #666;
        font-size: 14px;
      }
    }

    img {
      object-fit: cover;
      width: 88px;
      height: 88px;
      border-radius: 50%;
      box-shadow: 0px 0px 10px #00000033;
    }
  }
`;

export const DocumentsInfo = styled.div`
  margin-top: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;

  p {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin-bottom: 5px;
  }
`;

export const FileInfo = styled.a`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;

    strong {
      font-size: 12px;
      color: #666;
    }
  }

  img {
    object-fit: cover;
    width: 33px;
    height: 33px;
    border-radius: 5px;
    margin-right: 10px;
  }
`;

export const List = styled.li`
  display: flex;
  padding: 5px 0;

  a {
    align-self: center;
  }
`;
