import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label {
    align-self: flex-start;
    margin-bottom: 10px;
    font-weight: bold;
    color: #444;
  }

  input {
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 45px;
    padding: 0 15px;
    margin: 0 0 15px;
    font-size: 16px;
    transition: box-shadow 0.1s, border-color 0.1s;
    &::placeholder {
      color: #ccc;
    }

    &:focus {
      border-color: #c60000;
      box-shadow: 0 0 0 1px #c60000;
    }
  }

  span {
    color: #c60000;
    align-self: flex-start;
    margin: 0 0 10px;
    font-weight: bold;
  }
`;
