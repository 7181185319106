import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';

import pdfIcon from '~/assets/pdf-icon.png';

import api from '~/services/api';

import {
  Container,
  Content,
  Info,
  DocumentsInfo,
  List,
  FileInfo,
} from './styles';

export default function CpfConfirm({ modalVisible, client }) {
  const [show, setShow] = useState(false);
  const [documents, setDocuments] = useState(false);

  useEffect(() => {
    async function loadDocuments() {
      const response = await api.get(`documents/${client.id}`);

      if (response.data.length) {
        setDocuments(response.data);
      }
    }

    loadDocuments();
  }, [client.id]);

  function handleClose() {
    setShow(!show);
  }

  if (modalVisible === show) {
    return null;
  }

  return (
    <Container>
      <Content>
        <Info>
          <div className="header">
            <p>INFORMAÇÕES DO CLIENTE</p>
            <button type="button" onClick={() => handleClose()}>
              <MdClose size={20} />
            </button>
          </div>
          <div>{client.foto && <img src={client.foto.url} alt="Foto" />}</div>
          <div>
            Nome completo:
            <span> {client.nome}</span>
          </div>
          <div>
            CPF:
            <span> {client.cpf_formatted}</span>
          </div>
          {client.telefone && (
            <div>
              Telefone:
              <span> {client.telefone}</span>
            </div>
          )}
          {client.cep && (
            <div>
              CEP:
              <span> {client.cep}</span>
            </div>
          )}
          {client.uf && (
            <div>
              UF:
              <span> {client.uf}</span>
            </div>
          )}
          {client.cidade && (
            <div>
              Cidade:
              <span> {client.cidade}</span>
            </div>
          )}
          {client.endereco && (
            <div>
              Endereço:
              <span> {client.endereco}</span>
            </div>
          )}
          {client.numero !== null && (
            <div>
              Número:
              <span> {client.numero}</span>
            </div>
          )}
          {client.complemento && (
            <div>
              Complemento:
              <span> {client.complemento}</span>
            </div>
          )}
          {client.placa && (
            <div>
              Placa:
              <span> {client.placa}</span>
            </div>
          )}
        </Info>
        {documents && (
          <DocumentsInfo>
            <p>DOCUMENTOS DO CLIENTE</p>
            {documents.map((document) => (
              <List>
                <FileInfo href={document.url} target="_blank">
                  {document.name.indexOf('.pdf') !== -1 ? (
                    <img src={pdfIcon} alt="preview" />
                  ) : (
                    <img src={document.url} alt="preview" />
                  )}
                  <div>
                    <strong>{document.name}</strong>
                  </div>
                </FileInfo>
              </List>
            ))}
          </DocumentsInfo>
        )}
      </Content>
    </Container>
  );
}
