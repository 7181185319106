import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '~/pages/SignIn';

import CpfConfirm from '~/pages/CpfConfirm';
import Register from '~/pages/Register';
import Profile from '~/pages/Profile';
import AddClient from '~/pages/AddClient';
import ClientList from '~/pages/ClientList';
import EditClient from '~/pages/EditClient';
import Movement from '~/pages/Movement';
import GeralMovement from '~/pages/GeralMovement';
import Graphics from '~/pages/Graphics';
import TrocasOleo from '~/pages/TrocasOleo';
import TrocasOleoCreate from '~/pages/TrocasOleo/Create';
import TrocasOleoAlterar from '~/pages/TrocasOleo/Alterar';
import EnviarTrocasOleo from '~/pages/EnviarTrocasOleo';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/cpf-confirm" component={CpfConfirm} isPrivate />
      <Route path="/profile" component={Profile} isPrivate isTroca />

      <Route path="/register" component={Register} isPrivate isManager />

      <Route path="/add-client" component={AddClient} isPrivate isAdd />
      <Route path="/client-list" component={ClientList} isPrivate />
      <Route path="/edit-client" component={EditClient} isPrivate isClient />
      <Route path="/movement" component={Movement} isPrivate isClient />

      <Route path="/geral-movement" component={GeralMovement} isPrivate />
      <Route path="/graphics" component={Graphics} isPrivate />

      <Route path="/trocas-oleo" component={TrocasOleo} isPrivate isTroca />
      <Route
        path="/trocas-oleo-cadastro"
        component={TrocasOleoCreate}
        isPrivate
        isTroca
      />
      <Route
        path="/trocas-oleo-alterar"
        component={TrocasOleoAlterar}
        isPrivate
        isTroca
      />
      <Route
        path="/enviar-trocas"
        component={EnviarTrocasOleo}
        isPrivate
        isManager
      />
    </Switch>
  );
}
