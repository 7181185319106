/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

import api from '~/services/api';

import TableContainer from '~/components/TableContainer';
import Pagination from '~/components/Pagination';

import { Container, Content, HeaderList } from './styles';

export default function ClientList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [trocas, setTrocas] = useState([]);
  const [totalTrocas, setTotalTrocas] = useState(null);

  useEffect(() => {
    async function loadTrocas() {
      try {
        const response = await api.get('/enviar-trocas', {
          params: {
            page: currentPage,
            limit: 10,
          },
        });

        if (!response.data) {
          toast.warn('Nenhuma troca encontrada');
        }

        if (response.data.page > response.data.pages) {
          setCurrentPage(1);
        }

        setPages(response.data.pages);
        setTotalTrocas(response.data.total);
        setTrocas(response.data.items);
      } catch (err) {
        toast.error('Não foi possivel carregar as trocas');
      }
    }

    loadTrocas();
  }, [currentPage]);

  function handlePage(page) {
    if (page === 0) {
      setCurrentPage(1);
    } else if (page > pages) {
      setCurrentPage(pages);
    } else {
      setCurrentPage(page);
    }
  }

  async function whatsapp() {
    try {
      const response = await api.post('/enviar-whatsapp');

      const { semanal, mensal } = response.data;

      if (semanal !== 'Não há mais trocas semanais para serem enviadas') {
        window.open(semanal, '1');
      }
      if (mensal !== 'Não há mais trocas mensais para serem enviadas') {
        window.open(mensal, '2');
      }
    } catch (err) {
      toast.error('Não foi possivel baixar as trocas para enviar');
    }
  }

  async function email() {
    try {
      const response = await api.post('/enviar-email');

      const { semanal, mensal } = response.data;

      if (semanal !== 'Não há mais trocas semanais para serem enviadas') {
        toast.success('Trocas semanais enviadas');
      }
      if (mensal !== 'Não há mais trocas mensais para serem enviadas') {
        toast.success('Trocas mensais enviadas');
      }
    } catch (err) {
      toast.error('Não foi possivel enviar as trocas por email');
    }
  }

  return (
    <Container>
      <Content>
        <HeaderList>
          <h1>Enviar mensagens aos clientes</h1>
          <div className="header">
            <button type="button" className="email" onClick={() => email()}>
              E-mail
            </button>
            <button type="button" onClick={() => whatsapp()}>
              WhatsApp
            </button>
          </div>
        </HeaderList>
        <TableContainer>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Placa</th>
              <th>Email</th>
              <th>Whatsapp</th>
              <th>Vencimento</th>
            </tr>
          </thead>
          <tbody>
            {trocas.map((troca) => (
              <tr key={troca.id} className="itens">
                <td>{troca.nome}</td>
                <td>{troca.placa}</td>
                <td className={troca.send_email > 0 ? 'sim' : 'nao'}>
                  {troca.send_email === 2
                    ? 'Finalizado'
                    : troca.send_email === 1
                    ? 'Mensal'
                    : 'Não enviado'}
                </td>
                <td className={troca.send_whatsapp > 0 ? 'sim' : 'nao'}>
                  {troca.send_whatsapp === 2
                    ? 'Finalizado'
                    : troca.send_whatsapp === 1
                    ? 'Mensal'
                    : 'Não enviado'}
                </td>
                <td>{troca.formatted_troca}</td>
              </tr>
            ))}
          </tbody>
        </TableContainer>
        <Pagination
          currentPage={currentPage}
          pages={pages}
          totalDocs={totalTrocas}
          handlePage={handlePage}
        />
      </Content>
    </Container>
  );
}
