/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';

import HeaderList from '~/components/HeaderList';
import TableContainer from '~/components/TableContainer';
import Pagination from '~/components/Pagination';
import Troca from './Troca';

import { Container, Content } from './styles';

export default function ClientList() {
  const defaultValue = {
    value: 'nome',
    label: 'Nome',
  };

  const searchOptions = [
    {
      value: 'nome',
      label: 'Nome',
    },
    {
      value: 'placa',
      label: 'Placa',
    },
  ];

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [trocas, setTrocas] = useState([]);
  const [totalTrocas, setTotalTrocas] = useState(null);
  const [filter, setFilter] = useState(defaultValue);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    async function loadTrocas() {
      try {
        const response = await api.get('/trocas', {
          params: {
            filter: filter.value,
            q: search,
            page: currentPage,
            limit: 10,
          },
        });

        if (!response.data) {
          toast.warn('Nenhuma troca encontrada');
        }

        if (response.data.page > response.data.pages) {
          setCurrentPage(1);
        }

        setPages(response.data.pages);
        setTotalTrocas(response.data.total);
        setTrocas(response.data.items);
      } catch (err) {
        toast.error('Não foi possivel carregar as trocas');
      }
    }

    loadTrocas();
  }, [currentPage, reload]);

  useEffect(() => {
    async function loadTrocas() {
      try {
        const response = await api.get('/trocas', {
          params: {
            filter: filter.value,
            q: search,
            page: currentPage,
            limit: 10,
          },
        });

        if (!response.data) {
          toast.warn('Nenhuma troca encontrada');
        }
        setCurrentPage(1);

        setPages(response.data.pages);
        setTotalTrocas(response.data.total);
        setTrocas(response.data.items);
      } catch (err) {
        toast.error('Não foi possivel carregar as trocas');
      }
    }

    loadTrocas();
  }, [search]);

  function handlePage(page) {
    if (page === 0) {
      setCurrentPage(1);
    } else if (page > pages) {
      setCurrentPage(pages);
    } else {
      setCurrentPage(page);
    }
  }

  return (
    <>
      <Container>
        <Content>
          <HeaderList
            title="Listagem de trocas"
            page="trocas-oleo-cadastro"
            visible
            search={search}
            setSearch={setSearch}
            filter={filter}
            setFilter={setFilter}
            link
            defaultValue={defaultValue}
            searchOptions={searchOptions}
          />
          <TableContainer>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Placa</th>
                <th>Descrição</th>
                <th>Usuário</th>
                <th>Vencimento</th>
              </tr>
            </thead>
            <tbody>
              {trocas.map((troca) => (
                <Troca troca={troca} reload={reload} setReload={setReload} />
              ))}
            </tbody>
          </TableContainer>
          <Pagination
            currentPage={currentPage}
            pages={pages}
            totalDocs={totalTrocas}
            handlePage={handlePage}
          />
        </Content>
      </Container>
    </>
  );
}
