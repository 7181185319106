import styled from 'styled-components';

const TableContainer = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 20px;

  thead th {
    text-align: left;
    color: #444;
    font-size: 16px;
    padding: 6px 15px 0;

    &:last-child {
      text-align: right;
    }
  }

  tbody tr {
    background: #fff;
  }

  tbody td {
    height: 57px;
    padding: 6px 15px;
    color: #555;
    font-size: 16px;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      text-align: right;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        object-fit: cover;
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }
    }
  }
`;

export default TableContainer;
