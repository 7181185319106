/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { createUserRequest } from '~/store/modules/user/actions';
import Select from '~/components/Select';

import { Container, Content } from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  create_user_name: Yup.string().required('O usuário é obrigatório'),
  create_password: Yup.string().required('A senha é obrigatória'),
});

export default function Register() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);

  const defaultValue = {
    value: true,
    label: 'Sim',
  };

  const [trocaOleo, setTrocaOleo] = useState(true);

  const searchOptions = [
    {
      value: true,
      label: 'Sim',
    },
    {
      value: false,
      label: 'Não',
    },
  ];

  const handleChangeOption = (selectedOptionValue) => {
    setTrocaOleo(selectedOptionValue.value);
  };

  function handleSubmit({ name, create_user_name, create_password }) {
    dispatch(
      createUserRequest(name, create_user_name, create_password, trocaOleo)
    );
  }

  return (
    <Container>
      <Content>
        <Form schema={schema} onSubmit={handleSubmit}>
          <Input name="name" label="Nome" placeholder="Nome do usuário" />

          <Input
            name="create_user_name"
            label="Usuário"
            placeholder="Informe o usuário"
          />

          <Input
            name="create_password"
            label="Senha"
            type="password"
            placeholder="Informe a senha"
          />

          <div className="select">
            <label htmlFor="select">Apenas troca de óleo</label>
            <Select
              name="troca_oleo"
              placeholder="Apenas troca de oleo"
              options={searchOptions}
              defaultValue={defaultValue}
              onChange={handleChangeOption}
            />
          </div>

          <button type="submit">
            {loading ? 'Cadastrando...' : 'Cadastrar'}
          </button>
        </Form>
      </Content>
    </Container>
  );
}
