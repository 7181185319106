/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import { Container, Content, Info } from './styles';

export default function Modal({ modalVisible, troca, reload, setReload }) {
  const [show, setShow] = useState(false);

  function handleClose() {
    setShow(!show);
  }

  function Refresh() {
    setReload(!reload);
  }

  if (modalVisible === show) {
    return null;
  }

  async function handleDelete() {
    try {
      await api.delete(`/trocas/${troca.id}`);
      Refresh();
      toast.success('Troca de óleo excluida com sucesso');
    } catch (err) {
      toast.error('Erro ao excluir troca de óleo, verifique os dados');
    }
    handleClose();
  }

  function handleAlterar() {
    history.push({
      pathname: '/trocas-oleo-alterar',
      state: { troca },
    });
    handleClose();
  }

  return (
    <Container>
      <Content>
        <Info>
          <div className="header">
            <p>INFORMAÇÕES DA TROCA</p>
            <button type="button" onClick={() => handleClose()}>
              <MdClose size={20} />
            </button>
          </div>
          <div>
            Nome:
            <span> {troca.nome}</span>
          </div>
          <div>
            Placa:
            <span> {troca.placa}</span>
          </div>
          {troca.oleo && (
            <div>
              Descrição:
              <span> {troca.oleo}</span>
            </div>
          )}
          <div>
            Email:
            <span> {troca.email}</span>
          </div>
          <div className="datas">
            Celular:
            <span> {troca.telefone}</span>
          </div>
          <div>
            Email:
            <span className={troca.send_email > 0 ? 'sim' : 'nao'}>
              {' '}
              {troca.send_email === 2
                ? 'Finalizado'
                : troca.send_email === 1
                ? 'Mensal'
                : 'Não enviado'}
            </span>
          </div>
          <div>
            Whatsapp:
            <span className={troca.send_whatsapp > 0 ? 'sim' : 'nao'}>
              {' '}
              {troca.send_whatsapp === 2
                ? 'Finalizado'
                : troca.send_whatsapp === 1
                ? 'Mensal'
                : 'Não enviado'}
            </span>
          </div>
          {troca.user && (
            <div>
              Usuário:
              <span> {troca.user.name}</span>
            </div>
          )}
          <div>
            Trocado em:
            <span> {format(parseISO(troca.createdAt), 'dd/MM/yyyy')}</span>
          </div>
          <div>
            Vencimento:
            <span> {troca.formatted_troca}</span>
          </div>
          <button
            className="alterar"
            type="button"
            onClick={() => handleAlterar()}
          >
            ALTERAR
          </button>
          <button type="button" onClick={() => handleDelete()}>
            EXCLUIR
          </button>
        </Info>
      </Content>
    </Container>
  );
}
