import React, { useState, useEffect } from 'react';
import { MdChevronLeft, MdCheck } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';

import apiCep from '~/services/apiCep';
import history from '~/services/history';
import { addClientRequest, ResetPicture } from '~/store/modules/client/actions';

import Input from '~/components/Form/Input';
import InputMask from '~/components/Form/InputMask';
import AddUploaddocument from '~/components/AddUploadDocument';

import {
  Container,
  Content,
  Header,
  White,
  InputCamera,
  InputContainer,
  InputContainer2,
  InputContainer3,
  InputContainer4,
} from './styles';
import OpenCamera from './OpenCamera';

export default function AddClient() {
  const dispatch = useDispatch();

  const { cpf, foto } = useSelector((state) => state.client);

  const [visible, setVisible] = useState(false);
  const [defaultEndereco, setDefaultEndereco] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    function reset() {
      dispatch(ResetPicture());
    }

    reset();
  }, [dispatch]);

  function handleBack() {
    history.push('/cpf-confirm');
  }

  function handleSubmit(data) {
    const foto_id = foto.id;

    dispatch(addClientRequest(data, foto_id, uploadedFiles));
  }

  function handleOpen() {
    setVisible(!visible);
  }

  async function cepChanged(e) {
    const cep = e.target.value;

    if (cep.indexOf('_') === -1) {
      const response = await apiCep.get(`${cep}/json`);

      const { logradouro, bairro, localidade, uf } = response.data;

      setDefaultEndereco({ logradouro, bairro, localidade, uf });
    } else {
      setDefaultEndereco({});
    }
  }

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Header>
            <strong>Cadastro de clientes</strong>
            <div>
              <button type="button" className="cancel" onClick={handleBack}>
                <MdChevronLeft size={22} dolor="#fff" />
                VOLTAR
              </button>
              <button type="submit" className="confirm">
                <MdCheck size={22} color="#fff" />
                SALVAR
              </button>
            </div>
          </Header>
          <White>
            {foto.url ? (
              <button type="button" className="trocar" onClick={handleOpen}>
                <img src={foto.url} alt="Foto" />
              </button>
            ) : (
              <InputCamera type="button" onClick={handleOpen}>
                <strong>Adicionar foto</strong>
              </InputCamera>
            )}
            <InputContainer>
              <div>
                <Input name="nome" label="Nome" placeholder="Nome Completo" />
              </div>
              <div>
                <Input name="cpf" label="CPF" value={cpf} disabled />
              </div>
            </InputContainer>
            <InputContainer2>
              <div>
                <InputMask
                  name="telefone"
                  label="Telefone"
                  placeholder="(11) 99123-4567"
                  mask="(99) 99999-9999"
                />
              </div>
              <div>
                <InputMask
                  name="cep"
                  label="CEP"
                  placeholder="12345-678"
                  mask="99999-999"
                  onChange={cepChanged}
                />
              </div>
              <div>
                <Input
                  name="placa"
                  label="Placa"
                  placeholder="abc1234"
                  class="placa"
                />
              </div>
            </InputContainer2>
            <InputContainer3>
              <div>
                <Input
                  name="endereco"
                  label="Endereço"
                  placeholder="Informar o endereço"
                  defaultValue={
                    defaultEndereco.logradouro &&
                    `${defaultEndereco.logradouro} - ${defaultEndereco.bairro}`
                  }
                />
              </div>
              <div>
                <Input
                  name="numero"
                  label="Número"
                  type="number"
                  placeholder="Nº residência"
                />
              </div>
            </InputContainer3>
            <InputContainer4>
              <div>
                <Input
                  name="complemento"
                  label="Complemento"
                  placeholder="Complemento da redidência"
                />
              </div>
              <div>
                <Input
                  name="cidade"
                  label="Cidade"
                  placeholder="Cidade do cliente"
                  defaultValue={defaultEndereco.localidade}
                />
              </div>
              <div>
                {defaultEndereco.uf ? (
                  <Input
                    name="uf"
                    label="UF"
                    placeholder="SP"
                    defaultValue={defaultEndereco.uf}
                  />
                ) : (
                  <InputMask name="uf" label="UF" placeholder="SP" mask="aa" />
                )}
              </div>
            </InputContainer4>
            <InputContainer2>
              <div>
                <AddUploaddocument
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                />
              </div>
            </InputContainer2>
          </White>
        </Form>
        <OpenCamera visible={visible} />
      </Content>
    </Container>
  );
}
