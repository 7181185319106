import React, { useState } from 'react';
import { MdMoreHoriz, MdVisibility } from 'react-icons/md';

import TableAction from '~/components/TableAction';
import MovementView from './MovementView';

import { Container } from './styles';

export default function Actions({ venda }) {
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  function handleVisible() {
    setVisible(!visible);
  }

  function handleModalVisible() {
    setModalVisible(!modalVisible);
    setVisible(!visible);
  }

  return (
    <>
      <Container>
        <button type="button" onClick={handleVisible}>
          <MdMoreHoriz size={22} color="#c6c6c6" />
        </button>
        <TableAction visible={visible}>
          <div>
            <button type="button" onClick={handleModalVisible}>
              <MdVisibility size={18} color="#8E5BE8" />
              Visualizar
            </button>
          </div>
        </TableAction>
      </Container>
      <MovementView modalVisible={modalVisible} venda={venda} />
    </>
  );
}
