import React, { useState } from 'react';

import Modal from '../Modal';

export default function Troca({ troca, reload, setReload }) {
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  function handleModalVisible() {
    setModalVisible(!modalVisible);
    setVisible(!visible);
  }

  return (
    <>
      <tr key={troca.id} className="itens" onClick={handleModalVisible}>
        <td>{troca.nome}</td>
        <td>{troca.placa}</td>
        <td>{troca.oleo}</td>
        <td>{troca.user && troca.user.name}</td>
        <td>{troca.formatted_troca}</td>
      </tr>
      <Modal
        modalVisible={modalVisible}
        troca={troca}
        reload={reload}
        setReload={setReload}
      />
    </>
  );
}
