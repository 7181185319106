import React from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { validate } from 'gerador-validador-cpf';

import { toast } from 'react-toastify';
import FormContainer from '~/components/Form/FormContainer';
import InputMask from '~/components/Form/InputMask';

import history from '~/services/history';
import api from '~/services/api';

import { cpfConfirmed } from '~/store/modules/client/actions';
import { Container } from './styles';

const schema = Yup.object().shape({
  cpf: Yup.string().min(2).required('Favor informar o CPF'),
});

export default function CpfConfirm() {
  const dispatch = useDispatch();

  async function handleSubmit({ cpf }) {
    if (cpf[cpf.length - 2] === '_') {
      toast.error('Informe um CPF válido');
    } else {
      if (cpf[cpf.length - 1] === '_') {
        cpf = cpf.substring(0, 12) + 0 + cpf[12];
      }

      if (!validate(cpf)) {
        toast.error('Informe um CPF válido');
      } else {
        const response = await api.get(`/clientes/${cpf}`);

        if (!response.data) {
          dispatch(cpfConfirmed(cpf));
          history.push('/add-client');
        } else {
          toast.error('CPF informado já possui um cadastro');
        }
      }
    }
  }

  return (
    <Container>
      <FormContainer schema={schema} onSubmit={handleSubmit}>
        <section>
          <InputMask
            name="cpf"
            label="CPF"
            placeholder="Informe o CPF do cliente"
            mask="999.999.999-99"
          />
          <button type="submit">Cadastrar</button>
        </section>
      </FormContainer>
    </Container>
  );
}
