/* eslint-disable no-plusplus */
import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Webcam from 'react-webcam';
import { MdChevronLeft, MdCheck } from 'react-icons/md';

import { takePictureRequest } from '~/store/modules/client/actions';

import { Container, Content } from './styles';

export default function CpfConfirm({ visible }) {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const webcamRef = useRef(null);

  async function handleCapture() {
    const foto = webcamRef.current.getScreenshot();
    dispatch(takePictureRequest(foto));
    setShow(!show);
  }

  function handleClose() {
    setShow(!show);
  }

  if (visible === show) {
    return null;
  }

  const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: 'user',
  };

  return (
    <Container>
      <Content>
        <Webcam
          audio={false}
          width={390}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />
        <div>
          <button type="button" className="cancel" onClick={handleClose}>
            <MdChevronLeft size={22} color="#fff" />
            VOLTAR
          </button>
          <button type="button" className="confirm" onClick={handleCapture}>
            <MdCheck size={22} color="#fff" />
            SALVAR FOTO
          </button>
        </div>
      </Content>
    </Container>
  );
}
