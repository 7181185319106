import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 15px;
`;

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  thead th {
    text-align: left;
    color: #444;
    font-size: 14px;
    padding: 5px;
  }

  tbody td {
    padding: 5px;
    color: #555;
    font-size: 14px;
  }

  tbody tr:last-child {
    td {
      font-weight: bold;
    }
  }
`;
