import styled, { css } from 'styled-components';

const dragActive = css`
  border-color: #63cc2b;
`;

const dragReject = css`
  border-color: #c60000;
`;

export const Container = styled.div`
  label {
    align-self: flex-start;
    font-weight: bold;
    color: #444;
    margin-bottom: 10px;
  }
`;

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2s ease;
  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};
  margin-bottom: 10px;
`;

const messageColors = {
  default: '#999',
  error: '#c60000',
  success: '#63cc2b',
};

export const UploadMessage = styled.p`
  display: flex;
  color: ${(props) => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;

    strong {
      font-size: 14px;
      color: #444;
    }

    span {
      margin-top: 5px;

      button {
        border: 0;
        background: transparent;
        color: #e57878;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  img {
    object-fit: cover;
    width: 36px;
    height: 36px;
    border-radius: 5px;
    margin-right: 10px;
  }
`;

export const List = styled.li`
  display: flex;
  padding: 10px 0;
  margin-bottom: 10px;

  a {
    align-self: center;
  }
`;
