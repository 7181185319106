import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  background: #f5f5f5;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 730px;
  margin-top: 30px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    font-size: 24px;
    color: #444;
  }

  div {
    display: flex;

    button.cancel {
      padding: 8px 14px;
      background: #ccc;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 12px;
      margin-right: 10px;
      transition: background 0.2s;

      display: flex;
      align-items: center;

      &:hover {
        background: ${darken(0.03, '#ccc')};
      }

      svg {
        margin-right: 3px;
        align-items: center;
      }
    }

    button.confirm {
      padding: 8px 14px;
      background: #c60000;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 12px;
      transition: background 0.2s;

      display: flex;
      align-items: center;

      &:hover {
        background: ${darken(0.03, '#c60000')};
      }

      svg {
        margin-right: 5px;
      }
    }
  }
`;

export const White = styled.div`
  background: #fff;
  border-radius: 4px;
  margin-top: 15px;
  padding: 15px 5px 0 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 0px 10px #00000033;
  margin-bottom: 20px;

  button.trocar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    align-self: center;
    border: none;
    background: none;
    margin-bottom: 15px;
    box-shadow: 0px 0px 10px #00000033;

    img {
      object-fit: cover;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      border: 3px solid rgba(255, 255, 255, 0.3);
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    width: 100%;
    margin-right: 10px;

    input {
      display: block;
      width: 100%;
    }

    label {
      display: block;
    }
  }

  div: nth-child(2) {
    width: 50%;
  }

  .nome {
    text-transform: capitalize;
  }
`;

export const InputContainer2 = styled.div`
  display: flex;
  justify-content: space-between;

  div.noselect {
    width: 100%;
    margin-right: 10px;

    input {
      display: block;
      width: 100%;
    }

    label {
      display: block;
    }
  }

  div.select {
    width: 100%;
    margin-right: 10px;

    label {
      align-self: flex-start;
      margin-bottom: 10px;
      font-weight: bold;
      color: #444;
      display: block;
    }
  }

  .placa {
    text-transform: uppercase;
  }
`;
