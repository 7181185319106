/* eslint-disable require-yield */
import { all, takeLatest, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';
import { takePictureSuccess } from './actions';

export function* takePicture({ payload }) {
  function dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];

    for (let i = 0; i < binary.length; i += 1) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  }

  try {
    const { foto } = payload;

    const data = new FormData();
    const file = dataURItoBlob(foto);
    data.append('file', file, 'File.jpg');

    const response = yield api.post('/files', data);
    const { id, url } = response.data;

    yield put(takePictureSuccess(id, url));
  } catch (err) {
    toast.error('Erro ao enviar foto, tente novamente');
  }
}

export function* addClient({ payload }) {
  try {
    let { cpf } = payload.data;
    const {
      nome,
      telefone,
      cep,
      endereco,
      cidade,
      uf,
      complemento,
      placa,
    } = payload.data;
    let { numero } = payload.data;
    let { foto_id: id_foto } = payload;
    const { uploadedFiles } = payload;

    cpf = cpf.replace('.', '');
    cpf = cpf.replace('.', '');
    cpf = cpf.replace('-', '');

    if (!numero) {
      numero = null;
    }
    if (!id_foto) {
      id_foto = null;
    }

    const response = yield api.post('/clientes', {
      cpf,
      nome,
      telefone,
      cep,
      endereco,
      cidade,
      uf,
      id_foto,
      complemento,
      numero,
      placa,
    });

    uploadedFiles.forEach((file) => {
      const data = new FormData();
      data.append('file', file.file);
      api.post(`documents/${response.data.id}`, data);
    });

    toast.success('Cliente cadastrado com sucesso');
    history.push('/client-list');
  } catch (err) {
    toast.error('Erro ao cadastrar cliete, verifique os dados');
  }
}

export function* editClient({ payload }) {
  try {
    const {
      nome,
      telefone,
      cep,
      endereco,
      cidade,
      uf,
      complemento,
      placa,
    } = payload.data;
    let { numero } = payload.data;

    const { client_id, uploadedFiles } = payload;
    const { foto_id: id_foto } = payload;

    if (!numero) {
      numero = null;
    }

    uploadedFiles.forEach((file) => {
      if (!file.uploaded) {
        const data = new FormData();
        data.append('file', file.file);
        api.post(`documents/${client_id}`, data);
      }
    });

    if (!id_foto) {
      yield api.put(`/clientes/${client_id}`, {
        nome,
        telefone,
        cep,
        endereco,
        cidade,
        uf,
        complemento,
        numero,
        placa,
      });
    } else {
      yield api.put(`/clientes/${client_id}`, {
        nome,
        telefone,
        cep,
        endereco,
        cidade,
        uf,
        complemento,
        numero,
        placa,
        id_foto,
      });
    }

    toast.success('Cliente editado com sucesso');
    history.push('/client-list');
  } catch (err) {
    console.tron.log(err);
    toast.error('Erro ao editar cliete, verifique os dados');
  }
}

export default all([
  takeLatest('@client/TAKE_PICTURE_REQUEST', takePicture),
  takeLatest('@client/ADD_CLIENT_REQUEST', addClient),
  takeLatest('@client/EDIT_CLIENT_REQUEST', editClient),
]);
