/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { uniqueId } from 'lodash';
import Dropzone from 'react-dropzone';
import { MdLink } from 'react-icons/md';
import api from '~/services/api';

import pdfIcon from '~/assets/pdf-icon.png';
import documentIcon from '~/assets/document-icon.png';

import {
  FileInfo,
  DropContainer,
  UploadMessage,
  List,
  Container,
} from './styles';

export default function UploadDocument({
  uploadedFiles,
  setUploadedFiles,
  clientId,
}) {
  async function getDoc() {
    const response = await api.get(`documents/${clientId}`);
    const files = response.data.map((file) => ({
      id: file.id,
      name: file.name,
      uploaded: true,
      url: file.url,
      path: file.path,
    }));
    setUploadedFiles(files);
  }

  useEffect(() => {
    getDoc();
  }, []);
  function handleUpload(files) {
    const uploadedFile = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
      path: null,
    }));
    setUploadedFiles(uploadedFiles.concat(uploadedFile));
  }

  async function handleDelete(file) {
    if (file.uploaded) {
      await api.delete(`documents/${file.path}`);
      setUploadedFiles(uploadedFiles.filter((f) => f.path !== file.path));
    } else {
      setUploadedFiles(uploadedFiles.filter((f) => f.id !== file.id));
    }
  }

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return <UploadMessage>Arraste documentos aqui...</UploadMessage>;
    }

    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return (
      <UploadMessage type="success">Solte os documentos aqui</UploadMessage>
    );
  }
  return (
    <Container>
      <label>Documentos</label>

      <Dropzone
        accept="application/pdf, image/*"
        onDropAccepted={(file) => handleUpload(file)}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} />
            {renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        )}
      </Dropzone>
      {uploadedFiles &&
        uploadedFiles.map((file) => (
          <List key={file.id}>
            {file.uploaded ? (
              <>
                <FileInfo>
                  {file.name.indexOf('.pdf') !== -1 ? (
                    <img src={pdfIcon} alt="preview" />
                  ) : (
                    <img src={file.url} alt="preview" />
                  )}
                  <div>
                    <strong>{file.name}</strong>
                    <span>
                      {!!file.url && (
                        <button
                          type="button"
                          onClick={() => handleDelete(file)}
                        >
                          Excluir
                        </button>
                      )}
                    </span>
                  </div>
                </FileInfo>
                {file.url && (
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    <MdLink size={24} color="#222" />
                  </a>
                )}{' '}
              </>
            ) : (
              <FileInfo>
                {file.name.indexOf('.pdf') !== -1 ? (
                  <img src={pdfIcon} alt="preview" />
                ) : (
                  <img src={documentIcon} alt="preview" />
                )}
                <div>
                  <strong>{file.name}</strong>
                  <span>
                    <button type="button" onClick={() => handleDelete(file)}>
                      Excluir
                    </button>
                  </span>
                </div>
              </FileInfo>
            )}
          </List>
        ))}
    </Container>
  );
}
