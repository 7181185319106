import styled from 'styled-components';

export const Container = styled.td`
  position: relative;

  button {
    background: none;
    border: 0;

    svg {
      margin-top: 5px;
    }
  }
`;
