import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { user_name, password } = payload;

    const response = yield call(api.post, '/sessions', {
      user_name,
      password,
    });

    const { token, profile } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, profile));

    history.push('/client-list');
  } catch (err) {
    if (err.message === 'Network Error') {
      toast.error(
        'Falha ao conectar-se com o servidor, verifique sua internet'
      );
    } else {
      toast.error('Falha na autenticação, verifique seus dados');
    }
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
