export function cpfConfirmed(cpf) {
  return {
    type: '@client/CPF_CONFIRMED',
    payload: { cpf },
  };
}

export function takePictureRequest(foto) {
  return {
    type: '@client/TAKE_PICTURE_REQUEST',
    payload: { foto },
  };
}

export function takePictureSuccess(id, url) {
  return {
    type: '@client/TAKE_PICTURE_SUCCESS',
    payload: { id, url },
  };
}

export function addClientRequest(data, foto_id, uploadedFiles) {
  return {
    type: '@client/ADD_CLIENT_REQUEST',
    payload: { data, foto_id, uploadedFiles },
  };
}

export function addClientSuccess() {
  return {
    type: '@client/ADD_CLIENT_SUCCESS',
  };
}

export function clientNavigate(client) {
  return {
    type: '@client/CLIENT_NAVIGATE',
    payload: { client },
  };
}

export function ResetPicture() {
  return {
    type: '@client/RESET_PICTURE',
  };
}

export function editClientRequest(client_id, data, foto_id, uploadedFiles) {
  return {
    type: '@client/EDIT_CLIENT_REQUEST',
    payload: { client_id, data, foto_id, uploadedFiles },
  };
}
