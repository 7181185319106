import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  background: #f5f5f5;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-top: 30px;

  tr.itens {
    cursor: pointer;

    &:hover {
      background: rgba(255, 0, 0, 0.1);
    }
  }
`;
