import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MdSearch, MdAdd } from 'react-icons/md';
import { FaFileAlt } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import 'rsuite/dist/styles/rsuite-default.css';
import { DateRangePicker } from 'rsuite';

import api from '~/services/api';

import { Relatorio } from './Relatorio';

import Select from '../Select';

import { Container, SearchInput, SearchBar } from './styles';

export default function HeaderList({
  title,
  page,
  visible,
  search,
  setSearch,
  filter,
  setFilter,
  searchOptions,
  link,
  defaultValue,
  relatorio,
  id_cliente,
  periodo,
  setPeriodo,
}) {
  const [vendas, setVendas] = useState([]);
  const [tot, setTot] = useState(0);

  const handleChangeOption = (selectedOptionValue) => {
    setFilter(selectedOptionValue);
  };
  const componentRef = useRef();

  const toPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  async function handlePrint() {
    const response = await api.get('/vendas', {
      params: {
        id_cliente,
        filter: filter.value,
        q: search,
        periodo,
      },
    });
    setVendas(response.data.items);
    setTot(response.data.tot);
    toPrint();
  }

  function changeDate(value) {
    setPeriodo(value);
  }

  const { afterToday } = DateRangePicker;

  return (
    <Container visible={visible}>
      <h1>{title}</h1>
      <div className="header">
        <SearchBar>
          <SearchInput>
            <MdSearch size={22} color="#999" />
            <input
              type="text"
              placeholder={`Buscar por ${filter.label}`}
              value={search}
              onChange={(e) => [setSearch(e.target.value)]}
            />
          </SearchInput>
          <Select
            name="recipient.name"
            placeholder="Selecione um destinatário"
            options={searchOptions}
            defaultValue={defaultValue}
            onChange={handleChangeOption}
          />
          {relatorio && (
            <DateRangePicker
              placeholder="Definir um período"
              format="DD-MM-YYYY"
              disabledDate={afterToday()}
              onChange={(value) => changeDate(value)}
              style={{ marginLeft: '10px', zIndex: 1 }}
              locale={{
                sunday: 'Seg',
                monday: 'Ter',
                tuesday: 'Qua',
                wednesday: 'Qui',
                thursday: 'Sex',
                friday: 'Sab',
                saturday: 'Dom',
                ok: 'OK',
                today: 'Hoje',
                yesterday: 'Ontem',
                last7Days: 'Últimos 7 dias',
              }}
            />
          )}
        </SearchBar>
        {link && (
          <Link to={`/${page}`}>
            <MdAdd size={22} color="#fff" />
            CADASTRAR
          </Link>
        )}
        {relatorio && (
          <>
            <div style={{ display: 'none' }}>
              <Relatorio ref={componentRef} vendas={vendas} tot={tot} />
            </div>
            <button type="button" onClick={handlePrint}>
              <FaFileAlt size={22} color="#fff" />
              GERAR RELATÓRIO
            </button>
          </>
        )}
      </div>
    </Container>
  );
}

HeaderList.defaultProps = {
  search: null,
  setSearch: null,
  filter: null,
  setFilter: null,
  link: false,
  relatorio: false,
};

HeaderList.propTypes = {
  title: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  searchOptions: PropTypes.func.isRequired,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
  link: PropTypes.bool,
  relatorio: PropTypes.bool,
  defaultValue: PropTypes.func.isRequired,
};
