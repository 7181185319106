import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import logo from '~/assets/logo-vermelha.png';
import { Container, Content, Profile } from './styles';

export default function Header() {
  const { profile } = useSelector((state) => state.user);
  const { manager, trocaOleo } = useSelector((state) => state.auth);

  return (
    <Container>
      <Content>
        <nav>
          <img src={logo} alt="Palace" height="40" />
          {!trocaOleo && (
            <>
              <NavLink
                to="/client-list"
                activeClassName="selected"
                style={{ textDecoration: 'none' }}
              >
                LISTA DE CLIENTES
              </NavLink>
              <NavLink
                to="/geral-movement"
                activeClassName="selected"
                style={{ textDecoration: 'none' }}
              >
                LISTA DE VENDAS
              </NavLink>
              <NavLink
                to="/graphics"
                activeClassName="selected"
                style={{ textDecoration: 'none' }}
              >
                GRÁFICOS
              </NavLink>
              <NavLink
                to="/cpf-confirm"
                activeClassName="selected"
                style={{ textDecoration: 'none' }}
              >
                CADASTRAR CLIENTES
              </NavLink>
            </>
          )}
          <NavLink
            to="/trocas-oleo"
            activeClassName="selected"
            style={{ textDecoration: 'none' }}
          >
            TROCAS DE ÓLEO
          </NavLink>
          {manager && (
            <>
              <NavLink
                to="/register"
                activeClassName="selected"
                style={{ textDecoration: 'none' }}
              >
                CADASTRAR USUÁRIOS
              </NavLink>
            </>
          )}
        </nav>

        <aside>
          <Profile>
            <div>
              <strong>{profile.name}</strong>
              <NavLink to="/profile" style={{ textDecoration: 'none' }}>
                Meu perfil
              </NavLink>
            </div>
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}
