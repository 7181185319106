/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';

import HeaderList from '~/components/HeaderList';
import TableContainer from '~/components/TableContainer';
import Pagination from '~/components/Pagination';

import api from '~/services/api';

import Actions from './Actions';
import ClientView from './ClientView';
import { Container, Content } from './styles';

export default function GeralMovement() {
  const defaultValue = {
    value: 'posto',
    label: 'Posto',
  };
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(defaultValue);
  const [vendas, setVendas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [totalVendas, setTotalVendas] = useState(null);
  const [periodo, setPeriodo] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [client, setClient] = useState({});

  const searchOptions = [
    {
      value: 'posto',
      label: 'Posto',
    },
    {
      value: 'num_cupom',
      label: 'Nº Cupom',
    },
  ];

  useEffect(() => {
    async function loadVendas() {
      const response = await api.get('/vendas', {
        params: {
          filter: filter.value,
          q: search,
          page: currentPage,
          limit: 10,
          periodo,
        },
      });

      if (response.data.page > response.data.pages) {
        setCurrentPage(1);
      }

      setPages(response.data.pages);
      setTotalVendas(response.data.total);
      setVendas(response.data.items);
    }

    loadVendas();
  }, [currentPage]);

  useEffect(() => {
    async function loadVendas() {
      const response = await api.get('/vendas', {
        params: {
          filter: filter.value,
          q: search,
          page: currentPage,
          limit: 10,
          periodo,
        },
      });
      setCurrentPage(1);

      setPages(response.data.pages);
      setTotalVendas(response.data.total);
      setVendas(response.data.items);
    }

    loadVendas();
  }, [search, periodo]);

  function handlePage(page) {
    if (page === 0) {
      setCurrentPage(1);
    } else if (page > pages) {
      setCurrentPage(pages);
    } else {
      setCurrentPage(page);
    }
  }

  function handleShowClient(cliente) {
    setModalVisible(!modalVisible);
    setClient(cliente);
  }

  return (
    <>
      <Container>
        <Content>
          <HeaderList
            title="Listagem de vendas"
            page="cpf-confirm"
            visible
            search={search}
            setSearch={setSearch}
            filter={filter}
            setFilter={setFilter}
            searchOptions={searchOptions}
            defaultValue={defaultValue}
            relatorio
            periodo={periodo}
            setPeriodo={setPeriodo}
          />
          <TableContainer>
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Posto</th>
                <th>Nº Cupom</th>
                <th>Total da compra</th>
                <th>Data</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {vendas.map((venda) => (
                <>
                  <tr key={venda.id}>
                    <td>
                      <button
                        type="button"
                        onClick={() => handleShowClient(venda.cliente)}
                        style={{ background: 'none' }}
                        className="nome"
                      >
                        {venda.cliente.nome}
                      </button>
                    </td>
                    <td>{venda.empresa.nome}</td>
                    <td>{venda.num_cupom}</td>
                    <td>
                      {Number(venda.total).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </td>
                    <td>{format(parseISO(venda.created_at), 'dd/MM/yyyy')}</td>
                    <Actions venda={venda} />
                  </tr>
                </>
              ))}
            </tbody>
          </TableContainer>
          <Pagination
            currentPage={currentPage}
            pages={pages}
            totalDocs={totalVendas}
            handlePage={handlePage}
          />
        </Content>
      </Container>
      <ClientView client={client} modalVisible={modalVisible} />
    </>
  );
}
