import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  background: #f5f5f5;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-top: 30px;

  td.sim {
    font-weight: bold;
    color: #63cc2b;
  }

  td.nao {
    font-weight: bold;
    color: #ff4e4e;
  }
`;

export const HeaderList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  h1 {
    color: #444;
    font-size: 24px;
    margin-bottom: 20px;
  }

  div.header {
    display: flex;
    align-items: center;

    button {
      border: none;
      display: flex;
      align-items: center;
      background: #c60000;
      color: #fff;
      font-weight: bold;
      height: 36px;
      padding: 10px 16px;
      border-radius: 4px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, '#c60000')};
      }
    }

    button.email {
      margin-right: 10px;
    }
  }
`;
