import React from 'react';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { signInRequest } from '~/store/modules/auth/actions';

import logo from '~/assets/logo-branca.png';

import { Container, Content } from './styles';

const schema = Yup.object().shape({
  user_name: Yup.string().required('O usuário é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  function handleSubmit({ user_name, password }) {
    dispatch(signInRequest(user_name, password));
  }

  return (
    <Container>
      <Content>
        <img src={logo} alt="QueroMil" height="90" />

        <Form schema={schema} onSubmit={handleSubmit}>
          <Input name="user_name" placeholder="Seu usuário" />
          <Input name="password" type="password" placeholder="Sua senha" />

          <button type="submit">{loading ? 'Entrando...' : 'Acessar'}</button>
        </Form>
      </Content>
    </Container>
  );
}
