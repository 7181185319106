/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import 'rsuite/dist/styles/rsuite-default.css';
import { DateRangePicker } from 'rsuite';

import Select from '~/components/Select';

import api from '~/services/api';

import { Container, Content, SelectContainer } from './styles';

export default function Graphics() {
  const defaultPostoValue = {
    value: '',
    label: 'Todos',
  };
  const defaultMetodoValue = {
    value: '/products-graphic',
    label: 'Produto',
  };

  const [pieSeries, setPieSeries] = useState([]);
  const [barSeries, setBarSeries] = useState([]);

  const [posto, setPosto] = useState(defaultPostoValue);
  const [metodo, setMetodo] = useState(defaultMetodoValue);
  const [periodo, setPeriodo] = useState([]);

  const [pieOptions, setPieOptions] = useState({});
  const [barOptions, setBarOptions] = useState({});

  const postoOptions = [
    {
      value: '',
      label: 'Todos',
    },
    {
      value: 1,
      label: 'Petroisa',
    },
    {
      value: 2,
      label: 'Kit Barão',
    },
    {
      value: 3,
      label: 'Palace Rondon',
    },
  ];
  const metodoOptions = [
    {
      value: '/products-graphic',
      label: 'Produto',
    },
    {
      value: '/pagamentos-graphic',
      label: 'Pagamento',
    },
    {
      value: '/clients-graphic',
      label: 'Cliente',
    },
  ];

  function titleize(text) {
    const words = text.toLowerCase().split(' ');
    for (let a = 0; a < words.length; a += 1) {
      const w = words[a];
      words[a] = w[0].toUpperCase() + w.slice(1);
    }
    return words.join(' ');
  }

  useEffect(() => {
    async function loadSeries() {
      const response = await api.get(`${metodo.value}`, {
        params: {
          posto: posto.value,
          periodo,
        },
      });

      if (metodo.value === '/products-graphic') {
        const labels = [
          'Gasolina comum',
          'Gasolina aditivada',
          'Etanol comum',
          'Diesel S500',
          'Diesel S10',
        ];
        const yaxis = {
          labels: {
            formatter(value) {
              return `${value.toLocaleString('pt-BR')} Lts`;
            },
          },
        };
        setPieSeries(response.data.produtos);
        setPieOptions({
          chart: {
            width: 380,
            type: 'pie',
          },
          colors: [
            '#F94144',
            '#F8961E',
            '#F9C74F',
            '#90BE6D',
            '#43AA8B',
            '#277DA1',
          ],
          labels,
          yaxis,
        });
      } else if (metodo.value === '/pagamentos-graphic') {
        const yaxis = {
          labels: {
            formatter(value) {
              return `${value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}`;
            },
          },
        };
        /* const labels = [
          'Dinheiro',
          'Moeda',
          'Cheque a vista',
          'Cheque a prazo',
          'Cartão de débito',
          'Cartão de crédito',
        ]; */
        const labels = [];
        response.data.map((item) => {
          labels.push(item.nome);
        });

        const data = [];
        response.data.map((item) => {
          data.push(item.total);
        });

        setPieSeries(data);
        setPieOptions({
          chart: {
            width: 380,
            type: 'pie',
          },
          colors: [
            '#F94144',
            '#F8961E',
            '#F9C74F',
            '#90BE6D',
            '#43AA8B',
            '#277DA1',
          ],
          labels,
          yaxis,
        });
      } else {
        const data = [];
        const categories = [];
        response.data.map((item) => {
          data.push(item.total);
          categories.push(titleize(item.nome));
        });

        setBarSeries([
          {
            name: 'Compras',
            data,
          },
        ]);
        setBarOptions({
          chart: {
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              columnWidth: '50%',
              endingShape: 'rounded',
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 2,
          },

          grid: {
            row: {
              colors: ['#fff', '#f2f2f2'],
            },
          },
          xaxis: {
            labels: {
              rotate: -45,
            },
            categories,
            tickPlacement: 'on',
          },
          yaxis: {
            title: {
              text: 'Compras',
            },
            labels: {
              formatter(value) {
                return `${value.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}`;
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              type: 'horizontal',
              shadeIntensity: 0.25,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 0.85,
              opacityTo: 0.85,
              stops: [50, 0, 100],
            },
          },
        });
      }
    }

    loadSeries();
  }, [posto, metodo, periodo]);

  const handleChangePosto = (selectedOptionValue) => {
    setPosto(selectedOptionValue);
  };
  const handleChangeMetodo = (selectedOptionValue) => {
    setMetodo(selectedOptionValue);
  };

  function changeDate(value) {
    setPeriodo(value);
  }

  const { afterToday } = DateRangePicker;

  return (
    <Container>
      <Content>
        <SelectContainer>
          <div className="select">
            <label htmlFor="Posto">Posto</label>
            <Select
              id="Posto"
              name="selecionar"
              placeholder="Selecione um posto"
              options={postoOptions}
              defaultValue={defaultPostoValue}
              onChange={handleChangePosto}
            />
          </div>
          <div className="select">
            <label htmlFor="Filtro">Filtro</label>
            <Select
              id="Filtro"
              name="selecionar"
              placeholder="Selecione um posto"
              options={metodoOptions}
              defaultValue={defaultMetodoValue}
              onChange={handleChangeMetodo}
            />
          </div>
          <div className="select">
            <label htmlFor="Periodo">Período</label>
            <DateRangePicker
              id="Periodo"
              placeholder="Definir um período"
              format="DD-MM-YYYY"
              disabledDate={afterToday()}
              onChange={(value) => changeDate(value)}
              style={{ zIndex: 1 }}
              placement="bottomEnd"
              locale={{
                sunday: 'S',
                monday: 'T',
                tuesday: 'Q',
                wednesday: 'Q',
                thursday: 'S',
                friday: 'S',
                saturday: 'D',
                ok: 'OK',
                today: 'Hoje',
                yesterday: 'Ontem',
                last7Days: 'Últimos 7 dias',
              }}
            />
          </div>
        </SelectContainer>
        <div style={{ display: metodo.label !== 'Cliente' && 'none' }}>
          <Chart
            options={barOptions}
            series={barSeries}
            type="bar"
            width={380}
          />
        </div>
        <div style={{ display: metodo.label === 'Cliente' && 'none' }}>
          <Chart
            options={pieOptions}
            series={pieSeries}
            type="pie"
            width={380}
          />
        </div>
      </Content>
    </Container>
  );
}
