/* eslint-disable array-callback-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { format, parseISO } from 'date-fns';

import { Container, TableContainer } from './styles';

export class Relatorio extends React.PureComponent {
  render() {
    const { vendas, tot } = this.props;

    return (
      <Container>
        <TableContainer>
          <thead>
            <th>Data</th>
            <th>Posto</th>
            <th>Cupom</th>
            <th>Cliente</th>
            <th>Produto</th>
            <th>Quant.</th>
            <th>Preço</th>
            <th>Total</th>
          </thead>
          <tbody>
            {vendas.map((venda) => (
              <tr>
                <td>{format(parseISO(venda.created_at), 'dd/MM/yyyy')}</td>
                <td>{venda.empresa.nome}</td>
                <td>{venda.num_cupom}</td>
                <td>{venda.cliente.nome}</td>
                <td>
                  {venda.items.map((item) => (
                    <span>
                      {item.produto.descricao}
                      <br />
                    </span>
                  ))}
                </td>
                <td>
                  {venda.items.map((item) => (
                    <span>
                      {item.quantidade}
                      <br />
                    </span>
                  ))}
                </td>
                <td>
                  {venda.items.map((item) => (
                    <span>
                      {item.preco}
                      <br />
                    </span>
                  ))}
                </td>
                <td>
                  {Number(venda.total).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </td>
              </tr>
            ))}
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td>
                {tot.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td>
            </tr>
          </tbody>
        </TableContainer>
      </Container>
    );
  }
}
