/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/style-prop-object */
import React, { useState } from 'react';
import { MdChevronLeft, MdCheck } from 'react-icons/md';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import Input from '~/components/Form/Input';
import InputMask from '~/components/Form/InputMask';
import Select from '~/components/Select';

import {
  Container,
  Content,
  Header,
  White,
  InputContainer,
  InputContainer2,
} from './styles';

export default function TrocasOleoCreate() {
  const defaultValue = {
    value: 6,
    label: '6 meses',
  };

  const searchOptions = [
    {
      value: 6,
      label: '6 meses',
    },
    {
      value: 12,
      label: '12 meses',
    },
  ];

  const [duracao, setDuracao] = useState(defaultValue);

  function handleBack() {
    history.push('/trocas-oleo');
  }

  async function handleSubmit(data) {
    try {
      await api.post('/trocas', {
        nome: data.nome,
        telefone: data.telefone,
        email: data.email,
        placa: data.placa.toUpperCase(),
        duracao_oleo: duracao.value,
        oleo: data.oleo,
      });
      history.push('/trocas-oleo');
      toast.success('Troca de óleo cadastrada');
    } catch (err) {
      toast.error('Erro ao cadastrar troca de óleo, verifique os dados');
    }
  }

  const handleChangeOption = (selectedOptionValue) => {
    setDuracao(selectedOptionValue);
  };

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Header>
            <strong>Registrar troca de óleo</strong>
            <div>
              <button type="button" className="cancel" onClick={handleBack}>
                <MdChevronLeft size={22} dolor="#fff" />
                VOLTAR
              </button>
              <button type="submit" className="confirm">
                <MdCheck size={22} color="#fff" />
                SALVAR
              </button>
            </div>
          </Header>
          <White>
            <InputContainer>
              <div>
                <Input
                  name="nome"
                  label="Nome"
                  class="nome"
                  placeholder="Nome do cliente"
                />
              </div>
              <div>
                <InputMask
                  name="telefone"
                  label="Celular"
                  placeholder="(11) 99123-4567"
                  mask="(99) 99999-9999"
                />
              </div>
            </InputContainer>
            <InputContainer2>
              <div className="noselect">
                <Input
                  name="email"
                  label="Email"
                  placeholder="cliente@gmail.com"
                  type="email"
                />
              </div>
              <div className="noselect">
                <Input
                  name="placa"
                  label="Placa"
                  placeholder="Informe a placa"
                  class="placa"
                />
              </div>
              <div className="select">
                <label>Duração da troca</label>
                <Select
                  duracao_oleo
                  name="duracao_oleo"
                  placeholder="Selecione a duração da troca"
                  options={searchOptions}
                  defaultValue={defaultValue}
                  onChange={handleChangeOption}
                />
              </div>
            </InputContainer2>
            <div>
              <Input
                name="oleo"
                label="Informações da troca"
                placeholder="Informações do óleo, kilometragem..."
              />
            </div>
          </White>
        </Form>
      </Content>
    </Container>
  );
}
