import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import {
  updateProfileSuccess,
  updateProfileFailure,
  createUserSuccess,
  createUserFailure,
} from './actions';

export function* updateProfile({ payload }) {
  try {
    const { name, user_name, ...rest } = payload.data;

    const profile = {
      name,
      user_name,
      ...(rest.oldPassword && rest.password ? rest : {}),
    };

    const response = yield call(api.put, 'users', profile);

    if (response.data.error) {
      if (response.data.error === 'User already exists!') {
        toast.error('Nome de usuário já existe');
      } else if (
        response.data.error === 'Usuário não tem permissão para isso'
      ) {
        toast.error('Usuários dos postos só podem alterar a senha');
      } else {
        toast.error('Verifique se as senhas estão corretas');
      }
      yield put(updateProfileFailure());
    } else {
      toast.success('Perfil atualizado com sucesso');
      yield put(updateProfileSuccess(response.data));
    }
  } catch (err) {
    toast.error('Falha ao conectar-se com o servidor, verifique sua internet');
    yield put(updateProfileFailure());
  }
}

export function* createUser({ payload }) {
  try {
    const {
      name,
      create_user_name: user_name,
      create_password: password,
      trocaOleo: troca_oleo,
    } = payload;

    yield call(api.post, 'users', {
      name,
      user_name,
      password,
      troca_oleo,
    });

    toast.success('Usuário criado com sucesso');

    history.push('/client-list');

    yield put(createUserSuccess());
  } catch (err) {
    if (err.message === 'Network Error') {
      toast.error(
        'Falha ao conectar-se com o servidor, verifique sua internet'
      );
    } else {
      toast.error('Nome de usuário já existe');
    }
    yield put(createUserFailure());
  }
}

export default all([
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
  takeLatest('@user/CREATE_USER_REQUEST', createUser),
]);
