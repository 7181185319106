import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  background: #fff;
  width: 700;
  border: 0;
  border-radius: 4px;
  padding: 20px;

  video {
    border-radius: 4px;
    box-shadow: 0px 0px 10px #00000033;
  }

  div {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button.cancel {
      padding: 6px 10px;
      background: #ccc;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 12px;
      margin-right: 5px;
      transition: background 0.2s;

      display: flex;
      align-items: center;

      &:hover {
        background: ${darken(0.03, '#ccc')};
      }
    }

    button.confirm {
      padding: 6px 10px;
      background: #c60000;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 12px;
      transition: background 0.2s;

      display: flex;
      align-items: center;

      &:hover {
        background: ${darken(0.03, '#c60000')};
      }

      svg {
        margin-right: 5px;
      }
    }
  }
`;
