import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  height: 89%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 400px;
  background: #fff;
  padding: 25px 15px;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #00000033;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
      align-self: flex-start;
      margin-bottom: 10px;
      font-weight: bold;
      color: #444;
    }

    input {
      border: 1px solid #ddd;
      border-radius: 4px;
      height: 45px;
      padding: 0 15px;
      margin: 0 0 15px;
      font-size: 16px;
      transition: box-shadow 0.1s, border-color 0.1s;
      &::placeholder {
        color: #999;
      }

      &:focus {
        border-color: #c60000;
        box-shadow: 0 0 0 1px #c60000;
      }
    }

    span {
      color: #c60000;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    hr {
      border: 0;
      height: 1px;
      background: #ddd;
      margin: 5px 0 20px;
    }

    button.salvar {
      margin: 5px 0 0;
      height: 44px;
      background: #63cc2b;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, '#63CC2B')};
      }
    }
  }

  button.sair {
    width: 100%;
    margin: 5px 0 0;
    height: 44px;
    background: #c60000;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.03, '#c60000')};
    }
  }
`;
