import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { format, parseISO } from 'date-fns';

import { Container, Content, Info, List } from './styles';

export default function MovementView({ modalVisible, venda }) {
  const [show, setShow] = useState(false);

  function handleClose() {
    setShow(!show);
  }

  if (modalVisible === show) {
    return null;
  }

  return (
    <Container>
      <Content>
        <Info>
          <div className="header">
            <p>INFORMAÇÕES BÁSICAS</p>
            <button type="button" onClick={() => handleClose()}>
              <MdClose size={20} />
            </button>
          </div>
          <div>
            Nº Cupom:
            <span> {venda.num_cupom}</span>
          </div>
          <div>
            Posto:
            <span> {venda.empresa.nome}</span>
          </div>
          <div>
            Data:
            <span> {format(parseISO(venda.created_at), 'dd/MM/yyyy p')}</span>
          </div>
          <div>
            Total:
            <span>
              {' '}
              {Number(venda.total).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </span>
          </div>
        </Info>
        <List>
          <div className="header">
            <p>PRODUTOS</p>
          </div>

          {venda.items.map((item) => (
            <div className="item">
              <div>
                Produto:
                <span>
                  {' '}
                  {item.produto.descricao}
                  {' - '}
                  {Number(item.quantidade).toLocaleString('pt-BR')}{' '}
                  {item.produto.cod_produto <= 5 && 'Lts'}
                  {' * R$ '}
                  {Number(item.preco).toLocaleString('pt-BR', 3, {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </span>
              </div>
              <div>
                Total:
                <span>
                  {' '}
                  {Number(item.total).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </span>
              </div>
            </div>
          ))}
        </List>
        <List>
          <div className="header">
            <p>PAGAMENTO</p>
          </div>

          {venda.pagamentos.map((pagamento) => (
            <div className="item">
              <div>
                Forma:
                <span>
                  {' '}
                  {pagamento.forma.descricao}
                  {' - '}
                  {Number(pagamento.valor).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </span>
              </div>
            </div>
          ))}
        </List>
      </Content>
    </Container>
  );
}
