import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  background: #fff;
  width: 500px;
  border: 0;
  border-radius: 4px;
  padding: 20px;
`;

export const Info = styled.div`
  div.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    button {
      background: none;
      border: 0;
    }

    p {
      font-size: 16px;
      font-weight: bold;
      color: #000;
    }
  }

  div {
    margin-top: 10px;
    margin-bottom: 10px;

    font-weight: bold;
    color: #333;

    span {
      font-weight: normal;
      color: #666;
    }

    span.sim {
      font-weight: bold;
      color: #63cc2b;
    }

    span.nao {
      font-weight: bold;
      color: #ff4e4e;
    }

    a {
      display: flex;
      align-items: center;

      span {
        color: #666;
        font-size: 14px;
      }
    }
  }
  div.datas {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }

  > button {
    width: 100%;
    margin: 10px 0 0;
    height: 44px;
    background: #c60000;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.03, '#c60000')};
    }
  }

  button.alterar {
    background: #63cc2b;

    &:hover {
      background: ${darken(0.03, '#63cc2b')};
    }
  }
`;
