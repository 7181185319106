/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';

import HeaderList from '~/components/HeaderList';
import TableContainer from '~/components/TableContainer';
import Pagination from '~/components/Pagination';

import api from '~/services/api';

import Actions from './Actions';
import { Container, Content } from './styles';

export default function Movement() {
  const { client } = useSelector((state) => state.client);

  const defaultValue = {
    value: 'posto',
    label: 'Posto',
  };
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(defaultValue);
  const [vendas, setVendas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [totalVendas, setTotalVendas] = useState(null);
  const [periodo, setPeriodo] = useState([]);

  const searchOptions = [
    {
      value: 'posto',
      label: 'Posto',
    },
    {
      value: 'num_cupom',
      label: 'Nº Cupom',
    },
  ];

  useEffect(() => {
    async function loadVendas() {
      const response = await api.get('/vendas', {
        params: {
          id_cliente: client.id,
          filter: filter.value,
          q: search,
          page: currentPage,
          limit: 10,
          periodo,
        },
      });

      if (response.data.page > response.data.pages) {
        setCurrentPage(1);
      }

      setPages(response.data.pages);
      setTotalVendas(response.data.total);
      setVendas(response.data.items);
    }

    loadVendas();
  }, [currentPage]);

  useEffect(() => {
    async function loadVendas() {
      const response = await api.get('/vendas', {
        params: {
          id_cliente: client.id,
          filter: filter.value,
          q: search,
          page: currentPage,
          limit: 10,
          periodo,
        },
      });
      setCurrentPage(1);

      setPages(response.data.pages);
      setTotalVendas(response.data.total);
      setVendas(response.data.items);
    }

    loadVendas();
  }, [search, periodo]);

  function handlePage(page) {
    if (page === 0) {
      setCurrentPage(1);
    } else if (page > pages) {
      setCurrentPage(pages);
    } else {
      setCurrentPage(page);
    }
  }

  return (
    <Container>
      <Content>
        <HeaderList
          title={`Compas de ${client.nome}`}
          page="cpf-confirm"
          visible
          search={search}
          setSearch={setSearch}
          filter={filter}
          setFilter={setFilter}
          searchOptions={searchOptions}
          defaultValue={defaultValue}
          relatorio
          id_cliente={client.id}
          periodo={periodo}
          setPeriodo={setPeriodo}
        />
        <TableContainer>
          <thead>
            <tr>
              <th>Posto</th>
              <th>Nº Cupom</th>
              <th>Total da compra</th>
              <th>Pagamento</th>
              <th>Data</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {vendas.map((venda) => (
              <tr key={venda.id}>
                <td>{venda.empresa.nome}</td>
                <td>{venda.num_cupom}</td>
                <td>
                  {Number(venda.total).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </td>
                <td>{venda.pagamentos[0].forma.descricao}</td>
                <td>{format(parseISO(venda.created_at), 'dd/MM/yyyy')}</td>
                <Actions venda={venda} />
              </tr>
            ))}
          </tbody>
        </TableContainer>
        <Pagination
          currentPage={currentPage}
          pages={pages}
          totalDocs={totalVendas}
          handlePage={handlePage}
        />
      </Content>
    </Container>
  );
}
