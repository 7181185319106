import styled from 'styled-components';
import { Form } from '@unform/web';
import { darken } from 'polished';

const FormContainer = styled(Form)`
  width: 100%;
  max-width: 360px;
  background: #fff;
  padding: 60px 30px;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #00000033;
  display: flex;
  flex-direction: column;
  align-items: center;

  section {
    display: flex;
    flex-direction: column;
    width: 100%;

    button {
      margin: 5px 0 0;
      height: 44px;
      background: #c60000;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, '#c60000')};
      }
    }
  }
`;

export default FormContainer;
