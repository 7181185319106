/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import image from '~/assets/profile-image.png';

import HeaderList from '~/components/HeaderList';
import TableContainer from '~/components/TableContainer';
import Action from './Action';
import Pagination from '~/components/Pagination';

import { Container, Content } from './styles';

export default function ClientList() {
  const defaultValue = {
    value: 'nome',
    label: 'Nome',
  };

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [clients, setClients] = useState([]);
  const [totalClients, setTotalClients] = useState(null);
  const [filter, setFilter] = useState(defaultValue);

  const searchOptions = [
    {
      value: 'nome',
      label: 'Nome',
    },
    {
      value: 'cpf',
      label: 'CPF',
    },
    {
      value: 'telefone',
      label: 'Telefone',
    },
    {
      value: 'cep',
      label: 'CEP',
    },
    {
      value: 'endereco',
      label: 'Endereço',
    },
    {
      value: 'cidade',
      label: 'Cidade',
    },
    {
      value: 'uf',
      label: 'UF',
    },
    {
      value: 'placa',
      label: 'Placa',
    },
  ];

  useEffect(() => {
    async function loadClients() {
      try {
        const response = await api.get('/clientes', {
          params: {
            filter: filter.value,
            q: search,
            page: currentPage,
            limit: 10,
          },
        });

        if (!response.data) {
          toast.warn('Nenhum cliente encontrado');
        }

        if (response.data.page > response.data.pages) {
          setCurrentPage(1);
        }

        // console.tron.log('Página');

        setPages(response.data.pages);
        setTotalClients(response.data.total);
        setClients(response.data.items);
      } catch (err) {
        toast.error('Não foi possivel carregar os clientes');
      }
    }

    loadClients();
  }, [currentPage]);

  useEffect(() => {
    async function loadClients() {
      try {
        const response = await api.get('/clientes', {
          params: {
            filter: filter.value,
            q: search,
            page: currentPage,
            limit: 10,
          },
        });

        if (!response.data) {
          toast.warn('Nenhum cliente encontrado');
        }

        // console.tron.log('Pesquisa');

        setCurrentPage(1);

        setPages(response.data.pages);
        setTotalClients(response.data.total);
        setClients(response.data.items);
      } catch (err) {
        toast.error('Não foi possivel carregar os clientes');
      }
    }

    loadClients();
  }, [search]);

  function handlePage(page) {
    if (page === 0) {
      setCurrentPage(1);
    } else if (page > pages) {
      setCurrentPage(pages);
    } else {
      setCurrentPage(page);
    }
  }

  return (
    <Container>
      <Content>
        <HeaderList
          title="Listagem de clientes"
          page="cpf-confirm"
          visible
          search={search}
          setSearch={setSearch}
          filter={filter}
          setFilter={setFilter}
          searchOptions={searchOptions}
          link
          defaultValue={defaultValue}
        />
        <TableContainer>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Foto</th>
              <th>Nome</th>
              <th>CPF</th>
              <th>Telefone</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr key={client.id}>
                <td>
                  <div>
                    <img
                      src={client.foto ? client.foto.url : image}
                      alt="Avatar"
                    />
                  </div>
                </td>
                <td>{client.nome}</td>
                <td>{client.cpf_formatted}</td>
                <td>{client.telefone}</td>
                <Action client={client} />
              </tr>
            ))}
          </tbody>
        </TableContainer>
        <Pagination
          currentPage={currentPage}
          pages={pages}
          totalDocs={totalClients}
          handlePage={handlePage}
        />
      </Content>
    </Container>
  );
}
