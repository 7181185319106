import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 700px;
  background: #fff;
  padding: 30px 30px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #00000033;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;

  div.select {
    display: flex;
    flex-direction: column;
    label {
      align-self: flex-start;
      margin-bottom: 10px;
      font-weight: bold;
      color: #444;
    }
  }
`;
