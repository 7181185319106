import produce from 'immer';

const INITIAL_STATE = {
  client: null,
  cpf: null,
  foto: {},
};

export default function client(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@client/CPF_CONFIRMED': {
        draft.cpf = action.payload.cpf;
        break;
      }
      case '@client/TAKE_PICTURE_SUCCESS': {
        draft.foto.id = action.payload.id;
        draft.foto.url = action.payload.url;
        break;
      }
      case '@client/CLIENT_NAVIGATE': {
        draft.client = action.payload.client;
        break;
      }
      case '@client/RESET_PICTURE': {
        draft.foto = {};
        break;
      }

      default:
    }
  });
}
