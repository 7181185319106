import React from 'react';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';

import { updateProfileRequest } from '~/store/modules/user/actions';
import { signOut } from '~/store/modules/auth/actions';

import { Container, Content } from './styles';

export default function Profile() {
  const dispatch = useDispatch();
  const { profile, loading } = useSelector((state) => state.user);

  function handleSubmit(data) {
    dispatch(updateProfileRequest(data));
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <Content>
        <Form initialData={profile} onSubmit={handleSubmit}>
          <Input name="name" label="Nome" placeholder="Nome Completo" />
          <Input
            name="user_name"
            label="Usuário"
            placeholder="Nome de usuário"
          />

          <hr />

          <Input
            type="password"
            name="oldPassword"
            placeholder="Sua senha atual"
          />
          <Input type="password" name="password" placeholder="Nova senha" />
          <Input
            type="password"
            name="confirmPassword"
            placeholder="Confirmar nova senha"
          />

          <button type="submit" className="salvar">
            {loading ? 'Atualizando...' : 'Atualizar perfil'}
          </button>
        </Form>

        <button type="button" className="sair" onClick={handleSignOut}>
          Sair do sistema
        </button>
      </Content>
    </Container>
  );
}
