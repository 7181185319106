import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  MdMoreHoriz,
  MdVisibility,
  MdCreate,
  MdViewList,
} from 'react-icons/md';
import { useDispatch } from 'react-redux';

import TableAction from '~/components/TableAction';
import history from '~/services/history';
import { clientNavigate } from '~/store/modules/client/actions';
import ClientView from './ClientView';

import { Container } from './styles';

export default function Action({ client }) {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  function handleVisible() {
    setVisible(!visible);
  }

  function handleEdit() {
    dispatch(clientNavigate(client));
    history.push('/edit-client');
  }

  function handleCompras() {
    dispatch(clientNavigate(client));
    history.push('/movement');
  }

  function handleModalVisible() {
    setModalVisible(!modalVisible);
    setVisible(!visible);
  }

  return (
    <>
      <Container>
        <button onClick={handleVisible} type="button">
          <MdMoreHoriz size={22} color="#c6c6c6" />
        </button>

        <TableAction visible={visible}>
          <div>
            <button type="button" onClick={() => handleModalVisible()}>
              <MdVisibility size={18} color="#8E5BE8" />
              Visualizar
            </button>
          </div>
          <div>
            <button type="button" onClick={() => handleCompras()}>
              <MdViewList size={18} color="#63cc2b" />
              Compras
            </button>
          </div>
          <div>
            <button type="button" onClick={handleEdit}>
              <MdCreate className="edit" size={18} color="#4D85EE" />
              Editar
            </button>
          </div>
        </TableAction>
      </Container>
      <ClientView modalVisible={modalVisible} client={client} />
    </>
  );
}
