import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { lighten } from 'polished';

import { Container } from './styles';

export default function SelectComponent({
  placeholder,
  name,
  options,
  onChange,
  defaultValue,
  duracao_oleo,
}) {
  const customStyles = {
    control: () => ({
      display: 'flex',
      border: '1px solid #ddd',
      borderRadius: 4,
      width: duracao_oleo ? '100% ' : '180px',
      height: duracao_oleo ? '44px' : '36px',
      padding: '0 7px',
      color: '#999',
      background: '#fff',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#999',
    }),
    singleValue: (provided) => {
      const color = '#000';

      return { ...provided, color };
    },
  };

  return (
    <Container>
      <Select
        name={name}
        styles={customStyles}
        isSearchable={false}
        options={options}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#c60000',
            primary25: lighten(0.35, '#c60000'),
          },
        })}
        id="select"
      />
    </Container>
  );
}

SelectComponent.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  placeholder: PropTypes.string.isRequired,
};
